<template>
  <div class="content_li">
    <el-form
        :model="upData"
        :rules="rules"
        ref="ruleForm"
        :label-position="labelPosition"
        class="upData-form"
    >
      <div class="shebei">基本信息</div>
      <div class="info-style item-box1 item-box">
        <div class="box1">
          <el-form-item label="商品名称：" prop="commodityName">
            <el-input v-model="upData.commodityName" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="商品大分类：" prop="advertiseId">
            <el-select v-model="upData.advertiseId" @change="getSmallCategoriesInfoData" placeholder="请选择商品分类">
              <el-option
                  v-for="item in categoriesInfoList"
                  :key="item.id"
                  :label="item.categoriesName"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品小分类：" prop="smallAdvertiseId">
            <el-select v-model="upData.smallAdvertiseId" placeholder="请选择商品分类">
              <el-option
                  v-for="item in smallAdvertiseList"
                  :key="item.id"
                  :label="item.smallAdvertise"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品类型：" prop="commodityTypeId">
            <el-select v-model="upData.commodityTypeId" placeholder="请选择商品类型">
              <el-option
                  v-for="item in commodityTypeList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品物流：" prop="logisticsId">
            <el-select v-model="upData.logisticsId" placeholder="请选择商品物流">
              <el-option
                  v-for="item in logisticseList"
                  :key="item.id"
                  :label="item.configContent"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品服务：" prop="serveId">
            <el-select multiple v-model="serveId" placeholder="请选择商品服务">
              <el-option
                  v-for="item in serveList"
                  :key="item.id"
                  :label="item.configContent"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运费：" v-if="false">
            <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01"
                             :precision="2" :min="0" v-model="upData.freight" placeholder="请输入运费"></el-input-number>
          </el-form-item>
          <el-form-item label="运费模板：" prop="templateId">
            <el-select v-model="upData.templateId" placeholder="请选择运费模板">
              <el-option :label="item.template.title" :value="item.template.id" v-for="(item, index) in templateList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="兑换所需积分：" prop="points">
            <el-input type="number" v-model="upData.points" placeholder="请输入兑换所需积分"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="sort">
            <span v-if="type === 'details'">{{ upData.sort }}</span>
            <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1"
                             v-model="upData.sort" placeholder="请输入排序"></el-input-number>
          </el-form-item>
          <el-form-item label="商品图片：(只能上传jpg/png文件，且不超过2MB)" prop="commodityImgs">
            <div class="flexRow1">
              <div class="up-image" v-if="upData.commodityImgs.length>0">
                <div v-for="(item, index) in upData.commodityImgs" class="image-list" :key="index">
                  <img @click="handleSee(item)" :src="item" alt/>
                  <i class="el-icon-error" @click="removeImage(index)"></i>
                </div>
              </div>
              <el-upload
                  action
                  multiple
                  :limit="5"
                  accept=".jpg, .jpeg, .png, .gif"
                  :on-error="onError"
                  :on-exceed="onExceed"
                  :file-list="commodityImgs"
                  :show-file-list="false"
                  list-type="picture-card"
                  :auto-upload="true"
                  :http-request="fnUploadRequest"
                  :before-upload="beforeFileUpload"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <div class="shebei">商品描述</div>
    <div class="info-style editor-content">
      <rich-text-editor class="editor" v-model="upData.commodityDesc"></rich-text-editor>
    </div>

    <div class="footer-toolbar">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="handleSubmit">下一步</el-button>
    </div>

    <!--    <el-dialog :visible.sync="dialogVisible">-->
    <!--      <div class="see-img-box flexRow1 justifyContentCenter alignItems">-->
    <!--        <img width="70%" :src="dialogImageUrl" alt=""></div>-->
    <!--    </el-dialog>-->
    <picture-dialog :url="dialogImageUrl" :visible.sync="dialogVisible"/>
  </div>
</template>

<script>
import RichTextEditor from "../../../../richTextEditor";

let _this;
export default {
  name: "step-one",
  components: {RichTextEditor},
  props: {
    goodsId: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    // data: {
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // }
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val !== 'details') {
          this.getTypeList();
        }
      }
    },
    goodsId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getGoodsData(val);
        }
      }
    }
  },
  data() {
    _this = this
    const validatorPresaleTime = (rule, value, callback) => {
      if (this.upData.newProduct === 1) {
        if (value === "") {
          callback(new Error("请选择预售时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    const validatorPromotionTime = (rule, value, callback) => {
      if (this.upData.promotion === 1) {
        if (value === "" || value === null) {
          callback(new Error("请选择促销时间"));
        } else if(this.upData.promotionEndTime === "" || this.upData.promotionEndTime === null) {
          callback(new Error("请选择促销时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    const validatorPoints = (rule, value, callback) => {
      if (this.upData.exchange === 1) {
        if (value === 0) {
          callback(new Error("请输入兑换所需积分"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    const validatorLogisticsId = (rule, value, callback) => {
      if (value === undefined || value === "") {
        callback(new Error("请选择商品物流"));
      } else {
        callback();
      }
    }
    const validatorServeId = (rule, value, callback) => {
      if (this.serveId.length === 0) {
        callback(new Error("请选择商品服务"));
      } else {
        callback();
      }
    }
    const validatorSort = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error("请输入排序"));
      } else {
        callback();
      }
    }
    const validatorCommodityImgs = (rule, value, callback) => {
      let length = this.upData.commodityImgs.length;
      if (length === 0) {
        callback(new Error("请上传商品图片"));
        // callback();
      } else if (length > 5) {
        callback(new Error("商品图片最多上传2张"));
      } else {
        callback();
      }
    }
    return {
      labelPosition: "left",
      commodityImgs: [],
      allState: [
        {label: '否', value: 0},
        {label: '是', value: 1}
      ],
      commodityStatus: [
        {label: '下架', value: 1},
        {label: '上架', value: 2}
      ],
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        commodityName: [{required: true, message: "请输入商品名称", trigger: "blur"}],
        commodityTypeId: [{required: true, message: "请选择商品类型", trigger: "change"}],
        advertiseId: [{required: true, message: "请选择商品大分类", trigger: "change"}],
        smallAdvertiseId: [{required: true, message: "请选择商品小分类", trigger: "change"}],
        sort: [{required: true, validator: validatorSort, trigger: "blur"}],
        commodityImgs: [{required: true, validator: validatorCommodityImgs, trigger: "change"}],
        templateId: [{required: true, message: "请选择运费模板", trigger: "change"}],
        // commodityImgs: [{required: true,message: "请上传商品图片", trigger: "blur"}],
        logisticsId: [{required: true, validator: validatorLogisticsId, trigger: "change"}],
        serveId: [{required: true, validator: validatorServeId, trigger: "change"}],
        points: [{required: true, validator: validatorPoints, trigger: "blur"}],
        presaleTime: [{required: true, validator: validatorPresaleTime, trigger: "change"}],
        promotionTime: [{required: true, validator: validatorPromotionTime, trigger: "change"}]
      },
      upData: {
        commodityDesc: "",
        commodityImgs: [],
        commodityName: "",
        commodityStatus: 1,
        commodityTypeId: "",
        templateId: '',
        exchange: 1,
        freight: 0,
        limitedSale: 0,
        newProduct: 0,
        points: 0,
        presaleTime: "",
        promotion: 0,
        promotionTime: "",
        promotionEndTime: "",
        sellingPrice: 0,
        advertiseId: "",
        smallAdvertiseId: "",
        stock: 0,
        sort: 0
      },
      datePicker: [],
      commodityTypeList: [],
      allAttributesClassInfoList: [],
      smallAttrList: [],
      categoriesInfoList: [],
      smallAdvertiseList: [],
      configurationList: [],
      logisticseList: [],
      serveList: [],
      serveId: [],
      logisticsId: "",
      commodityDesc: "",
      attrDialog: false,
      attrType: "",
      smallAttrData: {
        attributesId: ""
      },
      smallAttrDialog: false,
      smallAttrType: "",
      templateList: []
    };
  },
  methods: {
    // 根据ID来查询商品数据
    getGoodsData(id) {
      let _this = this;
      _this.$api.getCommodityInfoById({id}).then(res => {
        if (res.data.code == 200) {
          _this.upData = res.data.data;
          _this.upData.commodityImgs = _this.upData.commodityImgs.split(",");
          _this.upData.freight = _this.upData.freight / 100;
          if(_this.upData.promotionTime === null || _this.upData.promotionEndTime === null) {
            _this.datePicker = [];
          } else {
            _this.datePicker = [_this.upData.promotionTime, _this.upData.promotionEndTime];
          }
          _this.getSmallCategoriesInfoData(_this.upData.advertiseId);
          _this.serveShow(_this.upData.configurationAssInfos);
        }
      });
    },
    // 回显商品服务
    serveShow(data) {
      if (data) {
        data.forEach(v => {
          _this.serveId.push(v.configId);
        })
      }
    },
    // 获取选择数据
    getTypeList() {
      let _this = this;
      _this.getCommodityTypeData();
      _this.getAllAttributesClassInfoData();
      _this.getAllCategoriesInfoData();
      _this.getAlllogisticseData();
      _this.getAllserveData();
      _this.getTemplateData();
    },
    // 模板列表
    getTemplateData() {
      let _this = this;
      if (_this.templateList.length > 0) {
        return;
      }
      const requestData = {
        page: 1,
        pageSize: 10000
      }
      _this.$api.getTemp(requestData).then(res => {
        if (res.data.code == 200) {
          _this.templateList = res.data.data.records;
        }
      });
    },
    // 获取商品类型
    getCommodityTypeData() {
      let _this = this;
      if (_this.commodityTypeList.length > 0) {
        return;
      }
      const requestData = {
        page: 1,
        pageSize: 10000,
        search: {
          optional: 1
        }
      }
      _this.$api.getCommodityTypeInfo(requestData).then(res => {
        if (res.data.code == 200) {
          _this.commodityTypeList = res.data.data.records;
        }
      });
    },
    // 获取属性
    getAllAttributesClassInfoData() {
      let _this = this;
      _this.$api.getAllAttributesClassInfo().then(res => {
        if (res.data.code == 200) {
          _this.allAttributesClassInfoList = res.data.data;
        }
      });
    },
    // 添加新属性
    addAttr() {
      let _this = this;
      _this.attrDialog = true;
      _this.attrType = 'add';
    },
    // 添加新属性值
    addSmallAttr() {
      let _this = this;
      _this.smallAttrDialog = true;
      _this.smallAttrType = 'add';
      _this.smallAttrData = {
        attributesId: _this.upData.advertiseId
      }
    },
    // 获取属性值
    getSmallAttributesClassInfoData(id) {
      let _this = this;
      _this.upData.attrsAssociateInfos[0].smallAttributesClassInfos[0].id = "";
      const requestData = {
        page: 1,
        pageSize: 1000,
        search: {
          attributesId: id
        }
      }
      _this.$api.getSmallAttributesClassInfo(requestData).then(res => {
        if (res.data.code == 200) {
          _this.smallAttrList = res.data.data.records;
        }
      });
    },
    // 商品属性和属性值发生变化
    changeAttr(data) {
    },
    // 处理促销时间
    changePicker(e) {
      this.upData.promotionTime = e[0];
      this.upData.promotionEndTime = e[1];
    },
    // 获取商品大分类
    getAllCategoriesInfoData() {
      let _this = this;
      if (_this.categoriesInfoList.length > 0) {
        return;
      }
      _this.$api.getAllCategoriesInfo().then(res => {
        if (res.data.code == 200) {
          _this.categoriesInfoList = res.data.data;
        }
      });
    },
    // 根据大分类ID获取商品小分类
    getSmallCategoriesInfoData(id) {
      let _this = this;
      _this.$api.getSmallById({id}).then(res => {
        if (res.data.code == 200) {
          _this.smallAdvertiseList = res.data.data;
        }
      });
    },
    // 商品配置
    getAllConfigurationInfoData(id) {
      let _this = this;
      _this.$api.getAllConfigurationInfo({id}).then(res => {
        if (res.data.code == 200) {
          const data = res.data.data;
          return data;
        }
      });
    },
    //
    getAlllogisticseData() {
      let _this = this;
      _this.$api.getAllConfigurationInfo({id: 1}).then(res => {
        if (res.data.code == 200) {
          _this.logisticseList = res.data.data;
        }
      });
    },
    getAllserveData() {
      let _this = this;
      _this.$api.getAllConfigurationInfo({id: 2}).then(res => {
        if (res.data.code == 200) {
          _this.serveList = res.data.data;
        }
      });
    },

    handleSubmit() {
      let _this = this;
      // this.$emit("one-submit",_this.upData);
      // return
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {

          if (_this.upData.commodityDesc === "") {
            _this.$message.error('请填写商品描述');
            return;
          }

          const imgList = [];
          const configurationAssInfos = [];

          // 商品图片
          // _this.commodityImgs.forEach((v) => {
          //   imgList.push(v.url);
          // })
          // _this.upData.commodityImgs = imgList.toString();
          // _this.upData.commodityImgs = _this.upData.commodityImgs.toString();

          // 商品服务
          _this.serveId.forEach(id => {
            _this.serveList.forEach(serve => {
              if (serve.id == id) {
                configurationAssInfos.push({
                  commodityId: _this.upData.id,
                  configId: id,
                })
              }
            })
          })

          const newRequestData = {
            configurationAssInfos: configurationAssInfos
          };
          // 过滤筛选
          const filterData = JSON.parse(JSON.stringify(_this.upData));
          for (let key in filterData) {
            // if (filterData[key]) {
            //   newRequestData[key] = filterData[key];
            // }
            newRequestData[key] = filterData[key];
          }
          // 将运费乘以100
          newRequestData.freight = newRequestData.freight * 100;
          this.$emit("one-submit", newRequestData);
        } else {
          // _this.$message.error('请填写完整！');
        }
      });
    },
    // 上传start
    handleRemove(file) {
      let _this = this;
      for (let i = 0; i < _this.commodityImgs.length; i++) {
        if (_this.commodityImgs[i].uid === file.uid) {
          _this.commodityImgs.splice(i, 1);
          break;
        }
      }
    },
    handleSee(url) {
      let _this = this;
      _this.dialogImageUrl = url;
      _this.dialogVisible = true;
    },
    removeImage(e) {
      let _this = this;
      _this.upData.commodityImgs.splice(e, 1);
      _this.commodityImgs.splice(e, 1);
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传5张");
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.commodityImgs.push(res);
        _this.upData.commodityImgs.push(res.url,)
      });
    }
    // 上传end

  }
}
</script>

<style scoped lang="less">
.upData-form {
  /deep/ .el-form-item {
    width: 90%;
    display: inline-block;
    margin-right: 3%;
  }
}

.xian {
  height: 1px;
  width: 100%;
  background: #c9cdcf;
}

.xiangqing {
  padding: 5px 10px;
  background: #f7fafc;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 20px;
}

.info-left {
  font-weight: 900;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.info-style {
  padding: 30px;
}

.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.dialog-footer {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
  display: inline-block;
}

.flexRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 20px 0;

  /deep/ .el-form-item {
    flex-direction: column;
    justify-content: space-between;
  }
}

.new {
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 20px;
  background: #f7fafc;
}

.justSpace {
  justify-content: space-between;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItems {
  align-items: center;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.title2 {
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
}

.title1 {
  font-size: 14px;
  color: #999999;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  margin-top: 30px;
}

.file-box {
  .file-content {
    display: flex;

    span {
      padding-right: 20px;
    }

    ul {
      display: flex;

      li {
        padding: 0 10px;
        color: #1890ff;

        &:hover {
          cursor: pointer;
          color: #1265b1;
        }
      }
    }
  }
}

.file-item {
  /deep/ .el-form-item {
    width: 100%;
  }

  /deep/ .el-upload {
    display: flex;
    align-items: center;
  }

  i {
    font-size: 24px;
    padding-left: 10px;

    &:hover {
      cursor: pointer;
      color: #e78585;
    }
  }
}

//.edit-template {
//  /deep/ .el-dialog__body {
//    height: 680px;
//  }
//}

.item-box {
  .box1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 20px 0;

    /deep/ .el-form-item {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .box2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  /deep/ .el-form-item__content {
    width: 100%;
    display: inline-block;
  }

  /deep/ .el-cascader {
    width: 100%;
  }
}

.content2 {
  padding: 30px 10px;
}

.content_li {
  padding: 0;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}

.content2 {
  padding: 0;
}

.item-box1 {
  padding-top: 0;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.el-input-number {
  width: 100%;
}

/deep/ .el-input-number.is-controls-right .el-input__inner {
  text-align: left;
}

.have-btn {
  display: flex;

  button {
    margin-left: 10px;
  }
}

.el-icon-circle-plus {
  font-size: 24px;
  color: #fa4242;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  position: relative;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}

.image-list img {
  width: 80px;
  height: 80px;
}

.el-icon-plus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 80px;
}

.editor-content {
  height: 600px;
}

.flexRow1 {
  display: flex;
}

.see-img-box {
  cursor: pointer;
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}
</style>

